<template>
  <v-row class="mt-2">
    <v-col cols="12" md="8">
      <div class="d-flex">
        <div class="d-flex">
          <v-subheader style="width: 100px"> Per Page </v-subheader>
          <v-select
            v-model="itemsPerPage_s"
            :items="pageItems"
            label="Per Page"
            dense
            style="width: 100px"
            @change="handleItemsPerPageChange"
            solo
          ></v-select>
        </div>
        <div class="d-flex">
          <v-subheader>
            <v-text class="mr-2">Page: </v-text>
            <v-text-field
              style="min-width: 60px; max-width: 60px; max-height: 45px"
              solo
              dense
              type="number"
              :max="pages"
              :min="1"
              v-model="page_s"
              @keyup="handlePageChange"
            />
          </v-subheader>
        </div>
        <div class="d-flex">
          <v-pagination
            v-model="page_s"
            :length="pages"
            @input="handlePageChange"
            :prev="handlePageChange"
          ></v-pagination>
          <v-btn
            text
            elevation="1"
            width="10"
            @click="$parent.refresh()"
            style="
              padding: 0px 0px !important;
              min-width: 40px;
              height: 35px;
              margin-top: 3px;
              margin-left: 10px;
            "
          >
            <v-icon>mdi-backup-restore </v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4">
      <div>
        <v-subheader class="float-right">
          Displaying {{ (page_s - 1) * itemsPerPage_s + 1 }}-{{
            page_s * itemsPerPage_s > totalNumberOfItems
              ? totalNumberOfItems
              : page_s * itemsPerPage_s
          }}
          of {{ totalNumberOfItems }}
        </v-subheader>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import "./pagination.scss";
export default {
  props: {
    page: {
      type: Number,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    totalNumberOfItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    pageItems: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      page_s: 1,
      pageItems: [],
      itemsPerPage_s: 100,
    };
  },

  watch: {
    pageItems: {
      immediate: true,
      handler(val, oldVal) {
        this.pageItems = val;
      },
    },
    itemsPerPage: {
      immediate: true,
      handler(val, oldVal) {
        this.itemsPerPage_s = val;
      },
    },
    page: {
      immediate: true,
      handler(val, oldVal) {
        this.page_s = val;
      },
    },
  },
  mounted() {
    this.page_s = this.page;
    this.itemsPerPage_s = this.itemsPerPage;
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    async handleItemsPerPageChange() {
      await this.$emit("update:itemsPerPage", this.itemsPerPage_s);
      this.$parent.handleItemsPerPageChange();
    },
    async handlePageChange() {
      await this.$emit("update:page", this.page_s);
      this.$parent.handlePageChange();
    },
  },
};
</script>
